
import Http from './Http'

export default {
    async login(credentials) {
        return await Http.post('auth/login', credentials)
    },
    async register(userData) {
        return await Http.post('/customers', userData)
    },
    async me() {
        return await Http.get('/user')
    },
    async validate() {
        const token = await Http.get('/auth/validate')
        const data = await token.data;
        return data;
    },
    async hasObjectPermission(data) {
        return await Http.patch('/auth/permission', data)
    },
    async auth2faStatus() {
        return await Http.get('/auth/2fa/status')
    },
    async generateQR() {
        return await Http.post('/auth/2fa/generateQR')
    },
    async confirm2FA(data) {
        return await Http.post('/auth/2fa/confirm', data)
    },
    async disable2FA(data) {
        return await Http.post('/auth/2fa/disable', data)
    },
    async changeAdminPassword(data) {
        return await Http.patch('/auth/admin/changePassword', data)
    },
    async getUsers(data) {
        return await Http.get(`/auth/users?${data}`)
    },
    async getRoles(data= '') {
        return await Http.get(`/auth/roles?${data}`)
    },
    async addUser(data){
        return await Http.post(`/auth/user`, data)
    },
    async editUser(data){
        return await Http.patch(`/auth/user`, data)
    },
    async deleteUser(user_id){
        return await Http.delete(`/auth/user/${user_id}`)
    },
    async changePassword(data){
        return await Http.patch(`/auth/user/changePassword`, data)
    },
}